@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Tajawal:wght@300;400;500;700;800;900&display=swap");

@font-face {
  font-family: "Expo Arabic Bold";
  src: url("/assets/Qyd-fonts/ExpoArabicBold.ttf");
}

@font-face {
  font-family: "Expo Arabic Light";
  src: url("/assets/Qyd-fonts/ExpoArabicLight.ttf");
}

body[dir="rtl"] {
  font-family: "Expo Arabic Light", sans-serif;

  .landing-page .contact-page .content-sec section header h4::after {
    left: unset !important;
    right: 0 !important;
  }

  .user-management .page-title h2 a img {
    transform: scale(-1, 1);
  }

  @media (min-width: 992px) {
    .profile-view .page-content .user-info .main-info {
      border-right: 0px solid #bcbcbc !important;
      border-left: 1px solid #bcbcbc;
    }
  }

  .form-check .form-check-input {
    float: revert;
    margin-right: -1.25rem;
  }

  .agent-registration {
    background-image: url("/assets/imgs/bg.svg");
    background-position: left;
    background-color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .badge {
    width: 101px !important;
  }

  .form-check-label {
    margin-right: 33px;
  }

  @media (min-width: 992px) {
    .app-sidebar {
      left: unset !important;
      right: 0;
    }

    .app-header {
      position: inherit !important;
    }
  }

  .folder.ds {
    right: unset !important;
    left: 0;
  }

  .input-group-text {
    right: unset !important;
    left: 0;
  }

  .profile-view .page-content .user-info .main-info .user-img .view-img button {
    right: unset !important;
    left: 7px !important;
  }

  .profile-view .page-content .user-info .action-edit-btn {
    right: unset !important;
    left: 0 !important;
  }

  .page-content .form-content .form-group .form-check label.sd,
  .page-content .form-content .form-group .form-check label {
    margin-right: 41px !important;
  }

  .page-content .form-content .form-group .form-check label.sd.ff,
  label.sd.ff {
    margin-right: 20px !important;
  }

  .navbar .dropdown-menu {
    left: 0 !important;
    right: unset;
  }

  .page-content .form-content .form-group .form-check label.ng {
    margin-right: 11px !important;
  }

  @media (min-width: 992px) {
    .profile-view .page-content .facility-info.border-sec {
      border-left: 1px solid #bcbcbc;
      padding-left: 16px;
      border-right: unset !important;
      padding-right: unset !important;
    }
  }

  .search-top .input-img button {
    right: unset !important;
    left: 14px;
  }

  .input-img.search-top img {
    right: 12px;
    left: 0 !important;
  }

  .input-img button.select {
    left: 35px !important;
    bottom: 4px !important;
  }

  .main .search .input-img button {
    right: unset !important;
    left: 26px;
    bottom: 4px;
  }

  .search-top .form-select,
  .form-select {
    padding: 0.775rem 1rem 0.775rem 3rem;
    background-position: left 1rem center;
  }

  .ngb-dp-navigation-select .form-select {
    padding: 4px 5px !important;
    background-position: center right !important;
  }

  .auth-sec {
    background-image: url("/assets/imgs/flower(AR).jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: revert;
  }

  .app-header {
    z-index: 100;
    position: fixed !important;
    left: unset;
    right: 0;
    top: 0;
    width: 100%;
  }

  .app-sidebar-toggle.btn.btn-icon.btn-shadow.btn-sm {
    left: 0 !important;
    right: unset;
  }

  .payment-package .page-content .payment-card .body ul li img {
    transform: unset !important;
  }

  .profile-page .page-title h2 a img {
    transform: scaleX(-1);
  }

  .page-content .form-content .form-group .password-input button {
    right: unset !important;
    left: 0 !important;
  }

  .input-container .dropdown__items {
    right: 0 !important;
  }

  .profile-view .page-title h3 a svg {
    transform: scale(-1, 1);
  }

  .input-container input {
    margin: 0;
    // direction: rtl;
  }

  // .input-container input:focus {
  //   direction: ltr;
  //   text-align: left;
  // }

  .agent-registration .page-title button svg {
    transform: unset !important;
  }

  .cameraSection {
    right: -42px !important;
  }

  .page-content .pass-sec .input-group-text {
    right: unset !important;
    left: 6px !important;
  }

  .form-gro .btn-info.ar-btn-icon {
    right: unset !important;
    left: 0 !important;
  }

  .form-wizard .form-check label {
    margin-left: 0 !important;
    margin-right: 51px;
  }

  .login-page .body-size .input-group .form-control.isValid,
  .form-control.is-valid.design {
    padding-right: calc(1.5em + 1.55rem);
    background-position: right calc(0.375em + 0.3875rem) center;
  }

  .login-page .body-size .input-group[dir="ltr"] .form-control {
    padding-right: 10px !important;
    background-position: right !important;
  }

  .login-page .body-size h1,
  .form-wizard .title h3,
  .forgot-pass h1 {
    font-family: "Readex Pro", sans-serif !important;
  }

  .login-page .body-size button,
  .form-wizard .panel-heading .steper .step-content .txt h6 {
    font-family: "Readex Pro", sans-serif !important;
    font-weight: 500;
  }

  .form-wizard .cont-btn .btn,
  .form-wizard .btn-group-justified .btn-success,
  .form-wizard .btn-group-justified .btn-primary,
  .forgot-pass .general-button {
    font-family: "Readex Pro", sans-serif !important;
    font-weight: 400;
  }

  .login-page .body-size .small-tit,
  .login-page .form-check label,
  .login-page .body-size a,
  .login-page .text-center,
  .form-wizard .form-gro input,
  .fv-plugins-message-container .fv-help-block,
  .form-wizard .have-acc,
  .form-wizard .have-acc a,
  .form-wizard label.new-lab,
  .form-wizard .form-check label,
  .form-wizard .form-check label a,
  .forgot-pass .small-tit,
  .have-acc,
  .forgot-pass .form-control,
  .forgot-pass .have-acc a,
  .login-page .body-size .form-control,
  .label-txt {
    font-family: "Readex Pro", sans-serif !important;
    font-weight: 300;
  }

  .step.step-log {
    margin: auto -18px auto 20px;
  }

  .log .bar:after {
    position: absolute;
    right: 0px;
    top: 0;
    content: "";
    border-right: 2px solid #a6a6a6;
    margin-right: 7px;
    border-left: none !important;
    margin-left: 0;
    height: 120%;
  }

  .form-check {
    width: 100%;
  }

  .menu-sub-indention .menu-item .menu-item .menu-link.active {
    margin-right: 0 !important;
  }

  .second {
    .label-img {
      right: 0;
      left: unset !important;
      transform: scale(1) !important;
      -webkit-transform: scale(1) !important;
    }
  }

  .third {
    .label-img {
      left: 0;
      right: unset !important;
      transform: scale(1) !important;
      -webkit-transform: scale(1) !important;
    }
  }

  .second {
    .hover::before {
      transform: scaleX(1) !important;
      -webkit-transform: scaleX(1) !important;
    }

    .circle {
      right: 15px;
      left: unset !important;
    }
  }

  .fourth {
    .circle {
      right: 15px;
      left: unset !important;
    }
  }

  .footer::before {
    transform: scale(1) !important;
    -webkit-transform: scale(1) !important;
  }

  span.num {
    right: -8px;
    left: unset;
  }

  .invoice-receipt {
    padding: 30px !important;

    .red-b {
      transform: scaleX(1) !important;
      -moz-transform: scaleX(1) !important;
      -webkit-transform: scaleX(1) !important;
      -ms-transform: scaleX(1) !important;
    }
  }

  .section3 .green,
  .section3 .yellow {
    transform: rotate(360deg) !important;
  }
}

.switchToggle input+label:before,
.switchToggle input+input+label:before {
  content: "Monthly";
  position: absolute;
  top: 5px;
  left: 35px;
  width: 50px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked+label:before,
.switchToggle input:checked+input+label:before {
  content: "Yearly";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 50px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}