html {
  scroll-behavior: smooth;
}

:root {
  --color: #0e0e0e;
}

@import "./assets/sass/style.scss";

@import "./assets/sass/plugins.scss";
@import "./assets/sass/style.angular.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";

// @import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

@font-face {
  font-family: "Expo Arabic";
  src: url("./assets/imgs/home/alfont_com_AlFont_com_ExpoArabic-SemiBold-1.ttf");
  font-display: swap;

}

.container {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.input-group-append button {
  border: 1px solid #048081 !important;
  border-radius: 0 !important;
  height: 100%;

  &:hover {
    background-color: #048081 !important;
    color: #fff;
  }

  &:focus {
    background-color: #048081 !important;
    color: #fff;
  }
}

.input-group-append button.active.btn {
  background-color: #048081 !important;

  &:hover {
    background-color: #048081 !important;
    color: #fff;
  }

  &:focus {
    background-color: #048081 !important;
    color: #fff;
  }
}


.ngb-dp-week.ngb-dp-weekdays.ng-star-inserted {
  width: 500px;
}

.dropdown-menu.show.ng-star-inserted {
  width: 500px;

}

.ngb-dp-navigation-select .form-select {
  width: 150px;
  margin: 10px;
  padding: 4px !important;
  background-position: center right !important;
}

// @font-face {
//   font-family: "Expo Arabic Bold";
//   src: url("/assets/Qyd-fonts/ExpoArabicBold.ttf");
// }

// @font-face {
//   font-family: "Expo Arabic Light";
//   src: url("/assets/Qyd-fonts/ExpoArabicLight.ttf");
// }

body {
  // font-family: "Expo Arabic";
  // font-family: "Lato", serif;
  font-family: "Cairo", serif !important;


  font-size: 14px;
  background-color: #fff;

  @media (min-height: 800px) {
    height: 100% !important;
  }
}

.table th,
.table td {
  font-size: 14px;
}

.table td {
  color: #6e7079 !important;
  font-weight: 400 !important;
}

.table thead tr th {
  color: #2c2d33 !important;
  background: #f0f0f0;
  padding: 20px 0;
  margin: 0;
}

.page-item.active .page-link {
  color: #106f6e;
  background-color: #106f6e45;
  font-weight: 700;
  border-radius: 20px;
}

.app-content {
  padding: 35px;
  background: #F9FAFB;
  padding-top: 30px !important;
  min-height: 100vh;
}

.genral-light-button {
  height: 50px;
  border-radius: 9px;
  color: var(--kt-primary);
  border-color: var(--kt-primary-light);
  background-color: var(--kt-primary-light);
}

.button-size {
  padding: 0% 15% 0% 15%;
}

a.link-primary {
  color: #0d0d0d !important;
  text-decoration: underline;
}

.action-btn {
  button {
    background-color: #272727;
    color: #fff;
    border-radius: 5px;
    padding: 11px 28px;
    border: none;
    font-size: 14px;

    i {
      color: #fff;
      padding: 0 5px;
    }
  }
}

.general-button {
  background-color: #212E51 !important;
  color: #fff !important;
  padding: 10px 30px !important;
  border-radius: 5px !important;

  i {
    color: #fff;
  }
}

.general-outline-button {
  background-color: #fff;
  color: #272727 !important;
  border: 1px solid #272727 !important;
  padding: 10px 35px !important;
  border-radius: 5px;
  min-width: 140px;
  height: 40px;
  font-weight: 600;

  i {
    color: #272727 !important;
  }
}

.back-button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  cursor: pointer;
}

.app-sidebar {
  background: url("assets/imgs/qyd/sidebar-bg.svg");
  background-position: center;
  background-size: cover;
}

.app-toolbar {
  display: none;
}

.table-design {
  margin-top: 40px;
  border: 1px solid #d6d2d2;
  border-radius: 25px;
  text-align: center;

  table {
    thead {
      background: #fcf0da;
      padding: 20px;

      tr {
        th {
          color: #363636;
          font-size: 16px;
          font-family: "Semibold";
          padding: 15px !important;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #d6d2d2;

        &:last-child {
          border: none;
        }

        button {
          all: unset;
          cursor: pointer;
        }

        .form-switch {
          display: inline-block;
        }

        .form-check-input:checked {
          background-color: #048081;
          border: none;
        }
      }
    }
  }

  .table tr,
  .table th,
  .table td {
    vertical-align: baseline;
  }
}

.toast-container {
  position: fixed !important;
}

.modal-dialog.modal-dialog-centered.modal-sm {
  max-width: 840px;
}

[dir="rtl"] .toast-top-right {
  top: 12px;
  left: 12px;
  right: auto;
}

.pagi-sec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .page-item {
    margin: 3px;

    &.active {
      .page-link {
        background: #0274b3;
      }
    }

    .page-link {
      background: #fff;
      border: 1px solid #e5e5e5;
    }
  }
}

td .d-flex .form-check label {
  margin: 0 !important;
}

[data-kt-app-sidebar-minimize="on"][data-kt-app-sidebar-hoverable="true"] .app-sidebar:not(:hover) .app-sidebar-menu .menu-title span {
  opacity: 0 !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

[data-kt-app-sidebar-minimize="on"][data-kt-app-sidebar-hoverable="true"] .app-sidebar:not(:hover) .app-sidebar-menu .menu-title svg,
[data-kt-app-sidebar-minimize="on"][data-kt-app-sidebar-hoverable="true"] .app-sidebar:not(:hover) .app-sidebar-menu .menu-title {
  opacity: 1 !important;
}

.form-check-input:checked {
  background-color: #048081;
  border-color: #048081;
}

.modal-dialog.modal-md {
  max-width: 651px;
}

.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #e6e6e6 !important;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
  min-height: 43px;
  padding-top: 10px;
  background: #fff;
}

.form-check-input:checked {
  background-color: #048081;
}

.form-check-input {
  cursor: pointer;
}

.badge {
  width: 110px !important;
  border-radius: 9px !important;
}

.input-group-text {
  z-index: 99999;
}

.input-container {
  border-radius: 10px;
  background-color: transparent;
  color: #3d3c42 !important;
  font-weight: 500 !important;
  height: 36.44px;
  border: none !important;
}

.ng-select-disabled {
  background-color: #ddd;
}

.ng-dropdown-panel {
  right: 0 !important;
}

.inValid {
  background-color: transparent !important;
}

.phoneInput {
  width: 100%;
  background: transparent;
  border: none;
  outline: none !important;
}

.table.gy-4 th {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-weight: bold;
}

.text-table {
  color: #106f6e !important;
}

option {
  padding: 5px !important;
}

.dropdown-item {
  padding: 7px;
}

.table-responsive {
  border-radius: 7px;
}

.card {
  background-color: transparent;
}

.modal-header {
  justify-content: end;
}

.main .search .input-img button {
  background-color: transparent !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
}

.ng-select.ng-select-single .ng-select-container {
  height: 42px !important;
}

.main .search .input-img button.select {
  top: 9px !important;
  right: 27px !important;
}

.main .search .input-img button {
  top: 13px !important;
  right: 12px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 3px;
}

.ng-dropdown-panel {
  border: 1px solid #ddd;
}

.dropdown-item i {
  padding: 0 0.5rem;
}

.main {
  margin-top: 30px;
}

.form-check {
  width: 100%;
}

.app-sidebar .menu .menu-item .menu-link .menu-title {
  color: #ffffff !important;
  font-size: 16px;
}

.input-group-text {
  z-index: 99;
}

@media (max-width: 600px) {
  .end-arabic {
    position: relative !important;
  }
}

.navbar-collapse {
  border-radius: 5px;
  text-align: center;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show,
.btn:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: rgb(155, 154, 154);
}



.ng-select .ng-clear-wrapper {
  color: white !important;
}

.iput-sec {
  display: block !important;
}

.owl-carousel .owl-item img {
  width: auto;
}

.badge-light-grey {
  /*grey*/
  color: #4590bf !important;
  background: #ebf3f8 !important;
}

/*new badge*/
.new-badge-light-green {
  /*green*/
  color: #519c66;
  background: #9cffda29;
}

.new-badge-light-red {
  /*red*/
  color: #e93f3f;
  background: #ffb5b529;
}

.new-badge-light-grey {
  /*grey*/
  color: #1c1d22;
  background: #a3a4aa29;
}

.new-badge-light-yellow {
  /*yellow*/
  color: #dda617;
  background: #fcd26529;
}

.new-badge-light-purple {
  /*purple*/
  color: #ba206a;
  background: #dd619c21;
}

.new-badge-light-pink {
  /*pink*/
  color: #e82f6a;
  background: #e82f6a21;
}

.new-badge-light-blue {
  /*blue*/
  color: #5570f1;
  background: #97cff229;
}

.new-badge-light-dark-yellow {
  /*dark-yellow*/
  color: #a3730c;
  background: #a3730c29;
}

.new-badge-light-light-yellow {
  /*light-yellow*/
  color: #a89453;
  background: #cda62629;
}

.new-badge-light-light-grey {
  /*light-grey*/
  color: #6d6262;
  background: #73737329;
}

.new-badge-light-dark-red {
  /*dark-red*/
  color: #980e10;
  background: #980e1029;
}

.main {
  @media (max-width: 500px) {
    padding: 0px !important;
    margin-top: 50px !important;
  }
}

.user-management {
  @media (max-width: 500px) {
    padding: 0px !important;
    margin-top: 50px !important;
  }
}

.switchToggle input+label:before,
.switchToggle input+input+label:before {
  content: "Monthly";
  position: absolute;
  top: 5px;
  left: 35px;
  width: 50px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

.switchToggle input:checked+label:before,
.switchToggle input:checked+input+label:before {
  content: "Yearly";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 50px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: #fff;
}

::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #048081;
  border-radius: 50px;
  // box-shadow: 0 0 40px 40px #048081;
}

::-webkit-scrollbar-track {
  background-color: #04808193;
  // box-shadow: 0 0 5px #00000069 inset;
}

::selection {
  background-color: #048081;
  color: #fff;
}


.multiselect-dropdown .dropdown-btn {
  padding: 11px !important;
}

.multiselect-dropdown .dropdown-btn .ng-star-inserted {
  text-align: right !important;
}

:host ::ng-deep .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  left: 1px !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  right: -4px;
}

.multiselect-item-checkbox input[type=checkbox]+div:after {
  right: 0px;

}

.multiselect-item-checkbox input[type=checkbox]+div {
  padding-right: 2em;
  text-align: right;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: none !important;
  border-radius: var(--Spacing-32, 32px) !important;
  background: rgba(9, 9, 11, 0.10) !important;
  font-size: 16px !important;
  color: #18181B !important;
  padding: 5px 10px !important;
  margin: 5px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  color: #700101 !important;
  cursor: pointer;
  padding: 10px;
}

.general-button {
  background-color: #212E51 !important;
  border: none;
  overflow: hidden;
  // font-family: Expo Arabic !important;
  font-size: 14px;

  &:hover {
    background-color: #141544db !important;
    box-shadow: 0 2px 4px 0 #141544c6;
    color: #fff;
    text-decoration: none;
    transition: all .5s;
  }

  &:hover::before {
    left: 100%;
    opacity: 0;
  }


  position: relative;

  &::before {
    background-color: hsla(0, 0%, 100%, .25);
    border-right: 1px solid #fff;
    content: "";
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transform: translate(-105%);
    transition: all .5s;
    width: 100%;
    z-index: 1;

  }
}

.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: 3rem !important;
  height: 2rem;
}

.ngb-dp-week.ngb-dp-weekdays.ng-star-inserted,
.ngb-dp-month .ngb-dp-week {
  justify-content: space-between !important;
}

.ngb-dp-weekday {
  color: #043e53 !important;

  font-weight: bold;
  font-style: normal !important;

}

.ngb-dp-day.ng-star-inserted {
  // width: 2rem !important;
  align-self: center;
  justify-content: space-between !important;

}

.general-outline-button {
  transition: 0.4s;
}

.general-outline-button i {
  transition: 0.4s;

}

.general-outline-button:hover {
  background-color: #1B1B1B !important;
  color: #fff !important;
}

.general-outline-button:hover i {
  color: #fff !important;
}

.btn-primary {
  background-color: #3B82F6 !important;
  border-color: #3B82F6 !important;
}

.btn-outline-dark {
  border: 1px solid #000 !important;
  color: #000;
  transition: 0.6s;
}

.btn-outline-dark:hover {
  background-color: #000;
  color: #fff;
}